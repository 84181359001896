import React from 'react';
import { Box, Heading, Button } from 'grommet';
import { Subtract } from 'grommet-icons';
import styled from 'styled-components';

const TitleContainer = styled(Heading)`
  flex-grow: 1;
`;

const Title = ({ label }) => (
  <TitleContainer
    margin={{ right: '2rem', left: '3rem', vertical: 'none' }}
    size="small"
    level="4"
    basis="xxsmall"
    textAlign="center"
    responsive
  >
    {label}
  </TitleContainer>
);

export const TitleBar = ({ close, label }) => (
  <Button onClick={close}>
    <Box
      direction="row"
      background="brand"
      pad="small"
      alignContent="stretch"
      align="center"
    >
      <Title label={label} />
      <Subtract color="plain" size="small" />
    </Box>
  </Button>
);
