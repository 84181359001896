import { useFormik } from 'formik';
import React, { useRef, useState, useContext } from 'react';
import { Box, Footer, Form, Spinner } from 'grommet';
import styled from 'styled-components';
import TextareaAutosize from 'react-autosize-textarea';
import { useAsync } from 'react-async-hook';

import { useInterval } from '../utils';
import { ChatBubble } from './ChatBubble';
import { SubmitButton } from './SubmitButton';
import { ChatBubbleUser } from './ChatBubbleUser';
import { useScrollToBottom } from '../hooks/useScrollToBottom';
import { NewMessageToast } from './NewMessageToast';
import { useOnScreen } from '../hooks/useOnScreen';
import { getChatHistory, sendMessage } from '../utils/api';
import { ShopContext } from '../contexts/shop';

const ChatForm = styled(Form)`
  border-top: 1px solid #f0f0f0;
  width: 100%;
`;

const StyledSubmitButton = styled(SubmitButton)`
  margin-left: ${({ theme }) => theme.global.edgeSize.xsmall};
`;

const StyledTextArea = styled(TextareaAutosize)`
  border: none;
  width: 100%;
  padding: ${({ theme }) => theme.global.edgeSize.small};
  line-height: ${({ theme }) => theme.global.font.height};
  font-size: ${({ theme }) => theme.global.font.size};
  font-family: ${({ theme }) => theme.global.font.family};
  resize: none;
  outline: none;
`;

const fetchMessages = async (chatId, shop, updateMessages) => {
  const history = await getChatHistory({ chatId, shop });

  updateMessages([...history].reverse());
};

const getInitialMessages = (initialMessage, isPreview) => {
  if (isPreview) {
    return [initialMessage, {
      "message_id": 605,
      "date": Math.floor(Date.now() / 1000) - 60 * 3,
      "chat": {
        "id": -540881229,
      },
      "text": "Hey there! Sure, what can I help you with?",
      "outgoing": true,
    }, {
      "message_id": 606,
      "date": Math.floor(Date.now() / 1000) - 60,
      "chat": {
        "id": -540881229,
      },
      "text": "I live in Toronto & was wondering if you're able to ship to my address within a week?",
      "outgoing": false,
    }, {
      "message_id": 607,
      "date": Math.floor(Date.now() / 1000),
      "chat": {
        "id": -540881229,
      },
      "text": "We usually ship within a week, but let me check specifically for your address. What's your shipping address?",
      "outgoing": true,
    }];
  }

  return [initialMessage];
};

export const Chat = ({ initialMessage, chatId, merchantName, merchantPicture, isPreview }) => {
  const [messages, setMessages] = useState({ items: getInitialMessages(initialMessage, isPreview).filter(Boolean), newItems: 0 });
  const [scrollToBottom, setScrollToBottom] = useState(null);
  const shop = useContext(ShopContext);

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: async ({ message }) => {
      if (isPreview) {
        return;
      }
      const newMessage = await sendMessage({
        chatId,
        shop,
        message,
      });
      formik.handleReset();
      setMessages({ items: [...messages.items, { ...newMessage, outgoing: false }], newItems: 0 });
    }
  })

  // Update messages only if there is a new message
  const updateMessages = fetchedMessages =>
    fetchedMessages.length !== messages.items.length ?
      setMessages({ items: fetchedMessages, newItems: (fetchedMessages.length - messages.items.length) + messages.newItems }) :
      null;

  const asyncFetchMessages = useAsync(() => !isPreview && fetchMessages(chatId, shop, updateMessages), [chatId, shop]);

  if (!isPreview) {
    const getMessages = () => !asyncFetchMessages.loading && asyncFetchMessages.execute(chatId, shop, updateMessages);
    useInterval(getMessages, 1000 * 5);
  }

  // Submit the message when the user presses enter
  const onKeyDown = ({ key }) => {
    if (key === 'Enter') {
      formik.handleSubmit();
    }
  };

  // Set up the scroll to bottom
  const messagesEndRef = useRef(null);
  const isBottomVisible = useOnScreen(messagesEndRef);

  // Mark all items as read when the user scrolls to the bottom
  if (isBottomVisible && messages.newItems > 0) {
    setMessages({ ...messages, newItems: 0 });
  }

  // Scroll to the bottom when the button is pressed or there is a new sent message
  const scrollTrigger = (scrollToBottom && !isBottomVisible ? 1 : 0) + messages.items.filter(message => !message.outgoing).length;
  useScrollToBottom(messagesEndRef, scrollTrigger);

  return (
    <Box justify="between" height="100%">
      <Box overflow="scroll" pad="small" style={{ position: "relative" }}>
        {messages.items.length === 0 && asyncFetchMessages.loading ? <Box align="center"><Spinner /></Box> : null}
        {messages.items.map((message, index) => {
          const didChangeIndex = messages.items[index - 1]?.outgoing !== message.outgoing;
          const shouldShowUser = didChangeIndex && message.outgoing;
          return (
            <Box key={message.message_id} flex={false}>
              {shouldShowUser && <ChatBubbleUser image={merchantPicture} name={merchantName} timestamp={message.date} />}
              <ChatBubble incoming={message.outgoing}>
                {message.text}
              </ChatBubble>
            </Box>
          );
        })}
        {
          !isBottomVisible &&
          messages.newItems > 0 &&
          <NewMessageToast
            count={messages.newItems}
            onClick={() => {
              setScrollToBottom(!scrollToBottom)
            }}
          />
        }
        <div ref={messagesEndRef} />
      </Box>
      <Footer>
        <ChatForm onSubmit={formik.handleSubmit} onKeyDown={onKeyDown}>
          <Box direction="row" justify="between" align="start">
            <StyledTextArea
              id="message-input"
              name="message"
              placeholder="Send a message…"
              onChange={formik.handleChange}
              value={formik.values.message}
              disabled={isPreview || formik.isSubmitting}
              rows={1}
              maxRows={5}
            />
            <StyledSubmitButton isSubmitting={formik.isSubmitting} />
          </Box>
        </ChatForm>
      </Footer>
    </Box>
  );
};
