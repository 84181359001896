import React from 'react';
import { FormField, TextArea, TextInput } from 'grommet';

export const FormInput = ({ name, label, formik, type = 'input', validate }) => {
  const id = `${name}-id-input`;
  const commonProps = {
    id,
    name,
    onChange: formik.handleChange,
    value: formik.values[name],
    disabled: formik.isSubmitting,
  };
  return (
    <FormField
      name={name}
      htmlFor={id}
      label={label}
      validate={validate}
    >
      {type === 'textarea' ?
        <TextArea
          {...commonProps}
          resize={false}
          rows={5}
        /> :
        <TextInput
          {...commonProps}
          type="text"
        />
      }

    </FormField>
  );
};
