{
  "name": "@shopify/app-bridge-react",
  "version": "3.1.1",
  "types": "index.d.ts",
  "main": "index.js",
  "unpkg": "umd/index.js",
  "jsdelivr": "umd/index.js",
  "files": [
    "/components/",
    "/umd/",
    "/hooks/",
    "/utilities/",
    "/context.d.ts",
    "/context.js",
    "/index.d.ts",
    "/index.js",
    "/types.d.ts",
    "/types.js",
    "/useAppBridge.d.ts",
    "/useAppBridge.js"
  ],
  "private": false,
  "publishConfig": {
    "access": "public",
    "@shopify:registry": "https://registry.npmjs.org"
  },
  "repository": "git@github.com:Shopify/app-bridge.git",
  "homepage": "https://shopify.dev/tools/app-bridge/react-components",
  "author": "Shopify Inc.",
  "license": "MIT",
  "scripts": {
    "build": "yarn build:tsc && yarn build:umd",
    "build:tsc": "NODE_ENV=production tsc",
    "build:umd": "NODE_ENV=production webpack -p",
    "check": "tsc",
    "clean": "cat package.json | node -pe \"JSON.parse(require('fs').readFileSync('/dev/stdin').toString()).files.map(f => './'+f).join(' ')\" | xargs rm -rf",
    "pack": "yarn pack",
    "size": "size-limit"
  },
  "sideEffects": false,
  "size-limit": [
    {
      "limit": "30 KB",
      "path": "index.js"
    }
  ],
  "dependencies": {
    "@shopify/app-bridge": "^3.1.1"
  },
  "devDependencies": {
    "@types/react": "^17.0.38",
    "enzyme": "3.11.0",
    "react-dom": "^17.0.2"
  },
  "peerDependencies": {
    "react": "^16.0.0 || ^17.0.0 || ^18.0.0"
  },
  "gitHead": "ae93a45eb69e6554d62e7cacb31064a347be98df"
}
