import React from 'react';
import { Box, Button } from 'grommet';
import { Chat } from 'grommet-icons';
import styled from 'styled-components';

const Container = styled(Box)`
  position: fixed;
  right: 0px;
  bottom: 0px;
  margin: 10px 20px;
  z-index: 99999;
`;

export const ChatButton = ({ open, style }) => (
  <Container style={style}>
    <Button
      onClick={open}
      icon={<Chat />}
      label="Chat"
      size="medium"
      primary
    />
  </Container>
);
