import React from 'react';
import { Box, Button, Text } from 'grommet';
import styled from 'styled-components';
import { LinkDown } from 'grommet-icons';

const StyledButton = styled(Button)`
  bottom: 0;
  position: sticky;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

export const NewMessageToast = (props) => {
  const label = `New Messages (${props.count})`;
  return (
    <StyledButton
      primary
      onClick={props.onClick}
      children={(props) =>
        <Box align="center">
          <Text size="small">{label}</Text>
          <LinkDown size="small" color="text" />
        </Box>
      }
    />
  )
}
