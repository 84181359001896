import {
  Card,
  ColorPicker,
  Form,
  FormLayout,
  Label,
  Layout,
  TextContainer,
  TextField,
  hsbToRgb,
  rgbToHsb,
  rgbString,
  DropZone,
  Thumbnail,
  Labelled,
  Stack,
  Caption,
  Popover,
  Button,
} from '@shopify/polaris';
import { useFormik } from 'formik';
import { useState } from 'react';

// import { ErrorBanner } from './ErrorBanner';
// import { SuccessBanner } from './SuccessBanner';
import { upsertSettings } from '../../api';
import { ChatDemo } from './ChatDemo';

const StatusBanner = ({ status }: { status: number }) => {
  // if (status >= 400) {
  //   return <ErrorBanner />;
  // }

  // if (status >= 200) {
  //   return <SuccessBanner />;
  // }

  // eslint-disable-next-line unicorn/no-null
  return null;
};

type Props = {
  shop: string;
  name?: string;
  color?: string;
  profilePic?: string;
};

export const SettingsForm = ({ shop, name = '', color = '', profilePic = '' }: Props) => {
  const [red = 0, green = 0, blue = 0] = ((color ?? '').match(/-{0,1}\d*\.{0,1}\d+/g) || []).map(Number);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      color: rgbToHsb({ red, green, blue }),
      name,
      profilePic: undefined,
    },
    onSubmit: async ({ color, name, profilePic }) => {
      const response = await upsertSettings({
        color: rgbString(hsbToRgb(color)),
        name,
        shop,
        profilePic,
      });

      formik.setStatus(response.status);
    },
  });

  const handleChange = (value: any, id: string) => formik.handleChange({ target: { id, value } });
  const validImageTypes = ['image/jpeg', 'image/png'];
  const fileUploadPreview = formik.values.profilePic ? window.URL.createObjectURL(formik.values.profilePic) : '';
  const rgbaColor = rgbString(hsbToRgb(formik.values.color));
  const [popoverActive, setPopoverActive] = useState(false);

  const activator = (
    <Button onClick={() => setPopoverActive(true)}>
      <Stack alignment="center" spacing="tight">
        <div
          style={{
            height: "2rem",
            width: "2rem",
            borderRadius: "0.3rem",
            background: rgbaColor,
          }}
        />
        <span>Primary color</span>
      </Stack>
    </Button>
  );

  const handleRgbChange = (value: string) => {
    const rgbValues = value.replace(/[^\d*.?\d*,]/g, "").split(",");
    const color = rgbToHsb({
      red: Number(rgbValues[0]),
      green: Number(rgbValues[1]),
      blue: Number(rgbValues[2]),
      alpha: Number(rgbValues[3]),
    });
    handleChange(color, 'color');
    console.log('color', color);
  }

  return (
    <Card
      title="Design"
      sectioned
      primaryFooterAction={{ content: 'Save', onAction: formik.submitForm }}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Layout>
          <StatusBanner status={formik.status} />
          <Layout.Section>
            <FormLayout>
              <TextContainer spacing="tight">
                <Label id="color" requiredIndicator>
                  Color
                </Label>
                <Popover
                  active={popoverActive}
                  activator={activator}
                  onClose={() => setPopoverActive(false)}
                >
                  <Popover.Section>
                    <ColorPicker
                      id="color"
                      color={formik.values.color}
                      onChange={(color) => handleChange(color, 'color')}
                      allowAlpha
                    />
                  </Popover.Section>
                  <Popover.Section>
                    <TextField autoComplete="off" label="" id="color" value={rgbaColor} onChange={handleRgbChange} />
                  </Popover.Section>
                </Popover>
              </TextContainer>
              <TextField
                id="name"
                value={formik.values.name}
                error={formik.errors.name}
                onChange={handleChange}
                disabled={formik.isSubmitting}
                label="Name"
                type="text"
                autoComplete="off"
                requiredIndicator
                helpText={<span>We’ll use this name for chat display.</span>}
              />
              <Labelled id="profilePic" label="Profile Picture">
                <div>
                  <DropZone
                    id="profilePic"
                    type="image"
                    onDrop={(_dropFiles, [acceptedFile]) =>
                      handleChange(acceptedFile, 'profilePic')
                    }
                    allowMultiple={false}
                  >
                    <div style={{ padding: '0' }}>
                      <Stack vertical>
                        {formik.values.profilePic && (
                          <Stack alignment="center">
                            <Thumbnail
                              size="small"
                              alt={formik.values.profilePic.name}
                              source={
                                validImageTypes.includes(formik.values.profilePic.type)
                                  ? fileUploadPreview
                                  : 'Not supported file type'
                              }
                            />
                            <div>
                              {formik.values.profilePic.name}{' '}
                              <Caption>{formik.values.profilePic.size} bytes</Caption>
                            </div>
                          </Stack>
                        )}
                      </Stack>
                    </div>
                    {!formik.values.profilePic && <DropZone.FileUpload />}
                  </DropZone>
                </div>
              </Labelled>
            </FormLayout>
          </Layout.Section>
          <Layout.Section secondary>
            <ChatDemo
              primaryColor={rgbaColor}
              name={formik.values.name}
              profilePic={formik.values.profilePic ? fileUploadPreview : profilePic}
            />
          </Layout.Section>
        </Layout>
      </Form>
    </Card>
  );
};
