import { TitleBar } from '@shopify/app-bridge-react';
import { Page, Heading, TextContainer } from '@shopify/polaris';

const Support = () => (
  <Page>
    <TitleBar title="Support"/>
    <TextContainer>
      <Heading>Help</Heading>
      <p>
        Email us:
        <a href="mailto:theslipcontact@gmail.com">theslipcontact@gmail.com</a>.
      </p>
    </TextContainer>
    <TextContainer>
      <Heading>GDPR</Heading>
      <p>We are fully integrated with the Shopify GDPR requirements.</p>
    </TextContainer>
  </Page>
);

export default Support;
