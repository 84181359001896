import { Banner, Layout } from '@shopify/polaris';

export const ErrorBanner = () => (
  <Layout.Section>
    <Banner status="critical">
      <p>
        Something went wrong. Please try again. If the issue persists, contact support@theslip.io.
      </p>
    </Banner>
  </Layout.Section>
);
