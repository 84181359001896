import { deepMerge } from 'grommet/utils';
import { grommet } from 'grommet/themes';

export const theme = deepMerge(grommet, {
  global: {
    font: {
      family: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      size: '16px',
      height: '18px',
    },
    colors: {
      brand: '#F99E00',
      focus: '#FFD3C4',
      text: { dark: '#f8f8f8', light: '#444444' },
    },
  },
  formField: {
    border: false,
    label: {
      margin: { horizontal: 'none' },
      size: 'small',
      weight: 'bold',
    },
  },
});
