import { useContext } from 'react';
import { Page, Loading, Frame } from '@shopify/polaris';
import { useAsync } from 'react-async-hook';

import { TitleBar } from '@shopify/app-bridge-react';

import { getSession } from '../api';
import { SetupForm } from '../components/setup';
import { ConnectedCard } from '../components/ConnectedCard';
import { DesignCard } from '../components/DesignCard';
import { ShopContext } from '../contexts/shop';

const Dashboard = () => {
  const shop = useContext(ShopContext);

  // Fetch already stored Telegram session.
  const { loading, result, error, execute } = useAsync(getSession, [shop]);
  const errorMessage = result?.error || error?.message;

  const sessionSetup = errorMessage ? (
    <SetupForm
      sessionId={result?.session_id || ''}
      shop={shop}
      error={errorMessage}
      reload={() => execute(shop)}
    />
  ) : (
    <ConnectedCard phoneLast4={result?.phone_last_4 || ''} reload={() => execute(shop)} />
  );

  return (
    <Page>
      <TitleBar title="Dashboard"/>
      {loading ? (
        <Frame>
          <Loading />
        </Frame>
      ) : (
        sessionSetup
      )}
      <DesignCard />
    </Page>
  );
};

export default Dashboard;
