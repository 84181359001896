{
  "surface": "#111213",
  "onSurface": "#111213",
  "interactive": "#2e72d2",
  "secondary": "#111213",
  "primary": "#008060",
  "critical": "#d82c0d",
  "warning": "#ffc453",
  "highlight": "#5bcdda",
  "success": "#008060",
  "decorative": "#ffc96b"
}