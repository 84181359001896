import { AppBridgeState, ClientApplication } from '@shopify/app-bridge';
import { authenticatedFetch } from '@shopify/app-bridge-utils';

import { API_HOST } from '../constants/environment';

const host = `${API_HOST}/shop`;

// type AuthData = {
//   session_id: string;
// };

type SessionData = {
  session_id: string;
  phone_last_4?: string;
  error?: string;
};
type SettingsData = {
  color?: string;
  name?: string;
  profile_pic?: string;
  shop?: string;
  error?: string;
};

const commonHeaders = {
  'Content-Type': 'application/json',
};

const commonOptions: globalThis.RequestInit = {
  mode: 'cors',
  headers: commonHeaders,
};

export const authorize = (app: ClientApplication<AppBridgeState>) => ({
  phone,
  password,
  shop,
  sessionId,
}: {
  phone: string;
  password: string;
  shop: string;
  sessionId: string;
}) => {
  const url = `${host}/auth?shop=${shop}&session_id=${sessionId}`;
  return authenticatedFetch(app)(url, {
    ...commonOptions,
    method: 'POST',
    body: JSON.stringify({ phone, password }),
  });
};

export const disconnect = async (shop: string) => {
  const url = `${host}/auth?shop=${shop}`;
  const response = await fetch(url, {
    ...commonOptions,
    method: 'DELETE',
  });

  return response.json();
};

export const submitCode = (shop: string, sessionId: string) => (code: string) => {
  const url = `${host}/auth/code?shop=${shop}&session_id=${sessionId}`;
  return fetch(url, {
    ...commonOptions,
    method: 'POST',
    body: JSON.stringify({ code }),
  });
};

export const getSession = async (shop: string): Promise<SessionData> => {
  const url = `${host}/session?shop=${shop}`;
  const response = await fetch(url, commonOptions);

  return response.json();
};

export const upsertSettings = ({
  color,
  name,
  shop,
  profilePic,
}: {
  color: string;
  name: string;
  shop: string;
  profilePic;
}) => {
  const url = `${host}/settings?shop=${shop}`;

  const formData = new FormData();
  formData.append('color', color);
  formData.append('name', name);
  formData.append('profile_pic', profilePic);

  return fetch(url, {
    ...commonOptions,
    method: 'PUT',
    headers: {},
    body: formData,
  });
};

export const getSettings = async (shop: string): Promise<SettingsData> => {
  const url = `${host}/settings?shop=${shop}`;
  const response = await fetch(url, commonOptions);

  return response.json();
};
