import React from 'react';
import { Button, Spinner } from 'grommet';
import { Send } from 'grommet-icons';

type SubmitButtonProps = {
  isSubmitting: boolean;
  className?: string;
}

export const SubmitButton = ({ isSubmitting, className }: SubmitButtonProps) => (
  <Button
    className={className}
    type="submit"
    disabled={isSubmitting}
    icon={isSubmitting ? <Spinner /> : <Send size="medium" color="plain" />}
  />
);
