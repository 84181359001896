import { useContext } from 'react';
import { Card, Layout, Banner } from '@shopify/polaris';
import { useAsync } from 'react-async-hook';

import { ShopContext } from '../contexts/shop';

import { disconnect } from '../api';

export const ConnectedCard = ({
  phoneLast4,
  reload,
}: {
  phoneLast4: string;
  reload: () => void;
}) => {
  const shop = useContext(ShopContext);
  const { execute } = useAsync(disconnect, [shop], {
    executeOnMount: false,
    executeOnUpdate: false,
  });
  return (
    <Card title="Telegram" sectioned>
      <Layout>
        <Layout.Section>
          <Banner
            status="success"
            action={{ onAction: () => execute(shop).then(reload), content: 'Disconnect' }}
          >
            <p>
              Your store is connected to Telegram with the following last 4 digits of the phone
              number <b>{phoneLast4}</b>.
            </p>
          </Banner>
        </Layout.Section>
      </Layout>
    </Card>
  );
};
