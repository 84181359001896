import { API_HOST } from '../env';

const commonHeaders = {
  'Content-Type': 'application/json'
}

const commonRequestParams = {
  mode: 'cors',
  headers: commonHeaders,
}

const getRequestParams = (method, data) => ({
  ...commonRequestParams,
  ...(data ? { body: JSON.stringify(data) } : {}),
  method,
})

const makeRequest = async (url, params) => {
  const response = await fetch(`${API_HOST}${url}`, params);

  return response.json();
}

/**
 * Starts a new chat.
 * @param {{shop: string, name: string|undefined, message: string, email: string}} param0 - The parameters to start a new chat.
 * @returns {Promise<{unknown}>} - The sent message data.
 */
export const startNewChat = ({ shop, name, message, email }) => {
  const url = `/chat?shop=${shop}`;

  return makeRequest(url, getRequestParams('POST', { name, message, email }))
}

/**
 * Receives the messages of a chat.
 * @param {{chatId: string, sessionId: string}} param0 - The chat ID & session ID of the chat.
 * @returns {Promise<{unknown}>} - The received messages.
 */
export const getChatHistory = ({ chatId, shop }) => {
  const url = `/chat/${chatId}/msg?shop=${shop}`;

  return makeRequest(url, getRequestParams('GET'));
}

/**
 * Sends a new message into a specific chat.
 * @param {{chatId: string, sessionId: string, message: string}} param0 - The chat ID, session ID, and the message to send to a chat.
 * @returns Promise<{unknown}> - The sent message data.
 */
export const sendMessage = ({ chatId, shop, message }) => {
  const url = `/chat/${chatId}/msg?shop=${shop}`;

  return makeRequest(url, getRequestParams('POST', { msg: message }));
}

/**
 * Gets chat settings for a specific shop.
 * @param {{shop: string}} param0 - The shop name.
 * @returns Promise<{unknown}> - The chat settings.
 */
export const getShopSettings = ({ shop }) => {
  const url = `/shop/settings?shop=${shop}`;

  return makeRequest(url, getRequestParams('GET'));
}
