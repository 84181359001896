import React, { useState } from 'react';
import { Grommet, Main } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { useAsync } from 'react-async-hook';

import { Chat } from './Chat';
import { ChatBox } from './ChatBox';
import { MessageForm } from './MessageForm';
import { useChatId } from '../utils/storage';
import { theme } from '../theme';
import { getShopSettings } from '../utils/api';
import { ShopContext } from '../contexts/shop';

const getInitialSettings = (isPreview) => {
  if (isPreview) {
    const [initialMessage, setInitialMessage] = useState({
      "message_id": 604,
      "date": "2021-07-21 01:38:56",
      "chat": {
        "id": -540881229,
      },
      "text": "Hi! Can you help me with something?",
      "outgoing": false
    });
    const [chatId, setChatId] = useState("chat-id");

    return {
      initialMessage,
      setInitialMessage,
      chatId,
      setChatId,
    }
  }

  const [initialMessage, setInitialMessage] = useState(null);
  const [chatId, setChatId] = useChatId();
  return {
    initialMessage,
    setInitialMessage,
    chatId,
    setChatId,
  }
}

export const Messaging = ({
  isPreview = false,
  name = 'Doge',
  image = 'https://i.gadgets360cdn.com/large/dogecoin_image_small_1618557722850.jpg',
  color = '',
  minimizedStyle = {},
  maximizedStyle = {},
  shop = window.Shopify?.shop || '',
}) => {
  const {
    initialMessage,
    setInitialMessage,
    chatId,
    setChatId,
  } = getInitialSettings(isPreview);

  const handleInitialMessage = (message, shop) => {
    setInitialMessage(message);
    setChatId(message.chat.id);
  };

  const { result: settings } = useAsync(() => !isPreview && getShopSettings({ shop }), [shop, isPreview]);

  const customTheme = deepMerge(theme, {
    global: {
      colors: {
        brand: isPreview ? color : settings?.color,
      },
    },
  });
  const merchantName = isPreview ? name : settings?.name || name;
  const merchantPicture = isPreview ? image : settings?.profile_pic || image;

  return (
    <Grommet theme={customTheme}>
      <ShopContext.Provider value={shop}>
        <ChatBox minimizedStyle={minimizedStyle} maximizedStyle={maximizedStyle}>
          {!chatId && <Main pad="medium"><MessageForm onMessageSubmitted={handleInitialMessage} /></Main>}
          {chatId && (
            <Chat
              isPreview={isPreview}
              chatId={chatId}
              initialMessage={initialMessage}
              merchantName={merchantName}
              merchantPicture={merchantPicture}
            />
          )}
        </ChatBox>
      </ShopContext.Provider>
    </Grommet>
  )
}
