import React from 'react';
import { Box } from 'grommet';
import styled from 'styled-components';

import { Container } from './Container';
import { TitleBar } from './TitleBar';
import { ChatButton } from './ChatButton';

const Content = styled(Box)`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const StyledContainer = styled(Container)`
  height: 500px;
`;

export const ChatBox = ({ children, minimizedStyle, maximizedStyle }) => {
  const [isOpen, setOpen] = React.useState(false);

  if (!isOpen) {
    return <ChatButton open={() => setOpen(true)} style={minimizedStyle} />;
  }

  return (
    <StyledContainer style={maximizedStyle}>
      <TitleBar
        close={() => setOpen(false)}
        label="Support"
      />
      <Content height="100%" overflow="scroll" isOpen={isOpen}>
        {children}
      </Content>
    </StyledContainer>
  );
};
