import { useContext } from 'react';
import { useAsync } from 'react-async-hook';

import { ShopContext } from '../contexts/shop';

import { getSettings } from '../api';
import { SettingsForm } from './settings';

export const DesignCard = () => {
  const shop = useContext(ShopContext);
  const { result } = useAsync(getSettings, [shop]);

  return <SettingsForm
    shop={shop}
    color={result?.color}
    name={result?.name}
    profilePic={result?.profile_pic}
  />;
};
