import React from 'react';
import { useFormik } from 'formik';
import { Box, Button, Form, Spinner } from 'grommet';
import { Send } from 'grommet-icons';

import { FormInput } from './FormInput';
import { startNewChat } from '../utils/api';

export const MessageForm = ({ onMessageSubmitted }) => {
  const formik = useFormik({
    initialValues: {
      shop: '',
      name: '',
      email: '',
      message: '',
    },
    onSubmit: async ({ shop, name, message, email }) => {
      const receivedMessage = await startNewChat({ shop, name, message, email });
      onMessageSubmitted({ ...receivedMessage, outgoing: false }, shop);
    },
  });
  return (
    <Form onSubmit={formik.handleSubmit}>
      <Box>
        {/* TODO: remove session ID field & read it from Shopify's settings */}
        <FormInput
          name="shop"
          label="Shop Domain"
          formik={formik}
          validate={{
            regexp: /[0-9a-fA-F]+/,
            message: "Please enter a shop domain name",
          }}
        />
        <FormInput
          name="name"
          label="Your name (optional)"
          formik={formik}
          validate={{
            regexp: /[0-9a-zA-Z]{3,}|^$/,
            message: "Please enter your name",
          }}
        />
        <FormInput
          name="email"
          label="Email address"
          formik={formik}
          validate={{
            regexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "Invalid email address",
          }}
        />
        <FormInput
          name="message"
          label="How can we help you?"
          formik={formik}
          type="textarea"
          validate={{
            regexp: /[0-9a-zA-Z]+/,
            message: "Please enter a message",
          }}
        />
        <Button
          type="submit"
          margin={{ top: 'small' }}
          size="medium"
          alignSelf="center"
          label="Send us a message"
          icon={formik.isSubmitting ? <Spinner /> : <Send />}
          primary
          fill
        />
      </Box>
    </Form>
  );
};
