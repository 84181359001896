import { useMemo } from 'react';
import { Routes as Switch, Route, useLocation, useNavigate } from 'react-router-dom'
import {useClientRouting, useRoutePropagation} from '@shopify/app-bridge-react';

import Dashboard from './pages/Dashboard';
import Support from './pages/Support';

export const Routes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Adapt between the new navigation API and the old history API that app-bridge expects
  const history = useMemo(() => {
    return {
      replace: (path: string) => navigate(path, {replace: true})
    }
  }, []);

  useClientRouting(history);
  useRoutePropagation(location);

  return (
    <Switch>
      <Route path="/" element={<Dashboard />} />
      <Route path="/support" element={<Support />} />
    </Switch>
  );
}

