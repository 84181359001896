import { useEffect, useState } from 'react';
import store from 'store2';

const storageKeys = {
  chatId: 'slip.chat.id',
  shop: 'slip.shop',
};

// Sets the value at the specified key in local storage.
const setter = (key) => (value) => {
  store.set(key, value, true);
};

// Gets the value at the specified key from local storage.
const getter = (key) => () => store.get(key);

// Creates a local storage React hook.
const createHook = (getStoredValue, setStoredValue) => () => {
  const [value, setId] = useState(getStoredValue());

  useEffect(() => setStoredValue(value), [value]);

  return [value, setId];
}

// Returns a React hook to get and set the session ID.
export const useChatId = createHook(
  getter(storageKeys.chatId),
  setter(storageKeys.chatId),
);

// Returns a React hook to get and set the session ID.
export const useShop = createHook(
  getter(storageKeys.shop),
  setter(storageKeys.shop),
);
