import { render } from 'react-dom';
import { BrowserRouter, Link } from "react-router-dom";

import '@shopify/polaris/build/esm/styles.css';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider, Button } from '@shopify/polaris';
import { NavigationMenu, Provider as AppBridgeProvider } from '@shopify/app-bridge-react';

import { getUrlParameter } from './helpers/url';
import { ShopContext } from './contexts/shop';
import { SHOPIFY_API_KEY } from './constants/environment';
import { Routes } from './Routes';

const App = () => {
  const url = new URL(window.location.href);
  const host = url.searchParams.get("host") || '';

  const config = {
    apiKey: SHOPIFY_API_KEY || '',
    host,
  };

  return (
    <BrowserRouter>
      <AppProvider i18n={enTranslations}>
        <AppBridgeProvider config={config}>
          <NavigationMenu
            navigationLinks={[
              {
                label: 'Dashboard',
                destination: '/',
              },
              {
                label: 'Support',
                destination: '/support',
              },
            ]}
          />

          <Link to='/'><Button>Fake Dashboard</Button></Link>
          <Link to='/support'><Button>Fake Support</Button></Link>
          <ShopContext.Provider value={getUrlParameter('shop') || ''}>
            <Routes />
          </ShopContext.Provider>
        </AppBridgeProvider>
      </AppProvider>
    </BrowserRouter>
  );
}

render(<App />, document.querySelector('#root') as HTMLElement);
