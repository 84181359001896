import { Box } from 'grommet';
import React from 'react';
import styled from 'styled-components';

export const BoxContainer = styled(Box)`
  position: fixed;
  right: 20px;
  bottom: 10px;
  z-index: 99999;
`;

export const Container = ({ children, className, isPositioningDisabled, style }) => (
  <BoxContainer
    style={style}
    round={{ corner: "top", size: "xsmall" }}
    height={{ max: 'calc(-32px + 100vh)', min: '0px', height: '500px' }}
    width={{ max: '94vw', min: '0px', width: '300px' }}
    overflow="hidden"
    background="white"
    elevation="xsmall"
    animation="slideUp"
    className={className}
  >
    { children }
  </BoxContainer>
);
