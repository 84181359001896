import React from 'react';
import { Box, Text } from 'grommet';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  ${({ incoming, theme }) => `border-top-${incoming ? 'left' : 'right'}-radius: ${theme.global.edgeSize.xsmall}`};
`;

export const ChatBubble = ({ incoming, children }) => (
  <StyledBox
    pad="small"
    round="medium"
    flex={false}
    background={incoming ? 'light-2' : 'brand'}
    align="start"
    justify="center"
    width={{ max: "small" }}
    margin={{ bottom: "xsmall" }}
    incoming={incoming}
    alignSelf={incoming ? 'start' : 'end'}
  >
    <Text color={incoming ? "black" : "white"} weight={500} size="small">
      {children}
    </Text>
  </StyledBox>
);
