{
  "name": "@shopify/app-bridge",
  "version": "3.1.1",
  "types": "index.d.ts",
  "main": "index.js",
  "unpkg": "umd/index.js",
  "jsdelivr": "umd/index.js",
  "files": [
    "/actions/",
    "/client/",
    "/umd/",
    "/util/",
    "/validate/",
    "/development.d.ts",
    "/development.js",
    "/index.d.ts",
    "/index.js",
    "/MessageTransport.d.ts",
    "/MessageTransport.js",
    "/production.d.ts",
    "/production.js"
  ],
  "private": false,
  "publishConfig": {
    "access": "public",
    "@shopify:registry": "https://registry.npmjs.org"
  },
  "repository": "git@github.com:Shopify/app-bridge.git",
  "homepage": "https://shopify.dev/tools/app-bridge",
  "author": "Shopify Inc.",
  "license": "MIT",
  "scripts": {
    "build": "yarn build:tsc && yarn build:npm && yarn build:umd",
    "build:tsc": "NODE_ENV=production tsc",
    "build:umd": "NODE_ENV=production webpack -p",
    "build:npm": "shx cp -r ./npm/index.js ./index.js",
    "check": "tsc",
    "clean": "cat package.json | node -pe \"JSON.parse(require('fs').readFileSync('/dev/stdin').toString()).files.map(f => './'+f).join(' ')\" | xargs rm -rf",
    "pack": "yarn pack",
    "size": "size-limit"
  },
  "sideEffects": false,
  "size-limit": [
    {
      "limit": "17 KB",
      "path": "production.js"
    }
  ],
  "dependencies": {
    "base64url": "^3.0.1"
  },
  "devDependencies": {
    "@types/node": "^10.12.5",
    "shx": "^0.3.3"
  },
  "gitHead": "ae93a45eb69e6554d62e7cacb31064a347be98df"
}
