import { Banner, Card, Form, FormLayout, Layout, TextField } from '@shopify/polaris';
import { useFormik } from 'formik';

import { VerifyTelegram } from './VerifyTelegram';
import { ErrorBanner } from './ErrorBanner';
import { SuccessBanner } from './SuccessBanner';
import { authorize } from '../../api';
import { useAppBridge, useAppBridgeState } from '@shopify/app-bridge-react';

const StatusBanner = ({ status }: { status: number }) => {
  if (status >= 400) {
    return <ErrorBanner />;
  }

  if (status >= 200) {
    return <SuccessBanner />;
  }

  // eslint-disable-next-line unicorn/no-null
  return null;
};

type Props = {
  shop: string;
  sessionId: string;
  error?: string;
  reload: () => void;
};

export const SetupForm = ({ shop, sessionId, error, reload }: Props) => {
  const app = useAppBridge()
  const formik = useFormik({
    initialValues: {
      phone: '',
      password: '',
    },
    onSubmit: async ({ phone, password }) => {
      const response = await authorize(app)({ phone, shop, password, sessionId });

      // Save session id if we got a 2xx response.
      if (response.status >= 200 && response.status < 300) {
        reload();
      }

      formik.setStatus(response.status);
    },
  });

  const handleChange = (value: string, id: string) =>
    formik.handleChange({ target: { id, value } });

  return (
    <Card
      title="Telegram"
      sectioned
      primaryFooterAction={{ content: 'Connect', onAction: formik.submitForm }}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Layout>
          <StatusBanner status={formik.status} />
          <Layout.Section>
            <Banner status="warning">
              <p>{error}</p>
            </Banner>
          </Layout.Section>
          <Layout.Section>
            <FormLayout>
              <TextField
                id="phone"
                value={formik.values.phone}
                error={formik.errors.phone}
                onChange={handleChange}
                disabled={formik.isSubmitting}
                label="Phone Number"
                type="tel"
                autoComplete="tel"
                inputMode="tel"
                requiredIndicator
                helpText={
                  <span>
                    Please enter your number in{' '}
                    <a
                      href="https://telegram.org/faq#login-and-sms"
                      target="_blank"
                      rel="noreferrer"
                    >
                      international format
                    </a>
                  </span>
                }
              />
              <TextField
                id="password"
                value={formik.values.password}
                error={formik.errors.password}
                onChange={handleChange}
                disabled={formik.isSubmitting}
                label="Password"
                type="password"
                autoComplete="off"
                requiredIndicator
                helpText={<span>We’ll use this password to connect to your Telegram account.</span>}
              />
            </FormLayout>
          </Layout.Section>
        </Layout>
      </Form>
      {formik.isSubmitting && (
        <VerifyTelegram
          sessionId={sessionId}
          shop={shop}
          close={() => formik.setSubmitting(false)}
        />
      )}
    </Card>
  );
};
