import { Modal, Form, FormLayout, TextField } from '@shopify/polaris';
import { useFormik } from 'formik';
import { submitCode } from '../../api';

type Props = {
  sessionId: string;
  shop: string;
  close: () => void;
};

export const VerifyTelegram = ({ sessionId, shop, close }: Props) => {
  const submit = submitCode(shop, sessionId);
  const formik = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: async (values) => {
      const response = await submit(values.code);
      formik.setStatus(response.status);
    },
  });

  const handleChange = (value: string, id: string) =>
    formik.handleChange({ target: { id, value } });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Modal
        open={formik.status !== 200}
        title="Verify Telegram"
        primaryAction={{
          content: 'Verify',
          onAction: formik.submitForm,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: close,
          },
        ]}
        onClose={close}
      >
        <Modal.Section>
          <FormLayout>
            <TextField
              id="code"
              value={formik.values.code}
              error={formik.errors.code}
              onChange={handleChange}
              label="Code"
              autoComplete="off"
              requiredIndicator
              inputMode="numeric"
              disabled={formik.isSubmitting}
              helpText={<span>Check your Telegram app for the code.</span>}
            />
          </FormLayout>
        </Modal.Section>
      </Modal>
    </Form>
  );
};
