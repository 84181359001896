import React from 'react';
import { Box, Image, Text } from 'grommet';
import styled from 'styled-components';
import TimeAgo from 'timeago-react';

const StatusCircle = styled(Box)`
  position: absolute;
  bottom: -5px;
  right: -5px;
`;

const PictureContainer = styled(Box)`
  position: relative;
`;

const Picture = ({ image }) =>
  <PictureContainer align="end">
    <Box height="xxsmall" width="xxsmall" flex={false} round="medium" overflow="hidden">
      <Image src={image} fit="cover" />
    </Box>
    <StatusCircle
      height="20px"
      width="20px"
      round="20px"
      flex={false}
      border={{ color: "white", size: "4px" }}
      background="status-ok"
    />
  </PictureContainer>;

const Info = ({ name, timestamp }) => {
  const date = new Date(timestamp * 1000);
  return (
      <Box margin={{ left: "small" }}>
      <Text size="small" weight={500}>{name}</Text>
      <Text size="xsmall" color="dark-6" title={date.toLocaleString()}>
        <TimeAgo datetime={date} opts={{ minInterval: 10 }} />
      </Text>
    </Box>
  );
}

// React component to display user in a chat bubble.
export const ChatBubbleUser = (props) =>
  <Box direction="row" align="center" margin={{ top: "small", bottom: "small" }}>
    <Picture image={props.image} />
    <Info name={props.name} timestamp={props.timestamp} />
  </Box>;
